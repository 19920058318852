import React from "react"
import Layout from "../layout"
import SEO from "../seo"
import { backendHost } from "../../config"
import Paginator from "../paginator"
import playButton from "../../assets/images/icons/play-2.svg"

export default function VideoGalleryPage({ pageContext }) {
  const data = pageContext

  return (
    <Layout>
      <SEO title="Videos" />
      <main>
        <div className="standard-container">
          <h2 className="relative uk-text-center mt6 mb5">
            Videos
            <div
              className="text-underline-heavy"
              style={{ marginLeft: "auto", marginRight: "auto" }}
            ></div>
          </h2>
          <div
            className="uk-grid-small uk-child-width-1-1 uk-child-width-1-4@s uk-text-center"
            data-uk-grid
            data-uk-lightbox="animation: slide"
          >
            {data.content.map(video => (
              <a href={`${video.media.publicURL}`}>
                <div className="animated-card animated-card-overlay-trigger">
                  <div className="uk-inline w-100">
                    <img
                      src={`${video.thumbnail.publicURL}`}
                      className="w-100"
                      alt=""
                    />
                    <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                      <img
                        src={playButton}
                        width="50px"
                        height="50px"
                        alt="play button"
                      />
                    </div>
                  </div>
                </div>
              </a>
            ))}
          </div>
          <div
            style={{
              marginTop: "60px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Paginator
              totalPages={data.totalPages}
              pageNumber={data.pageNumber}
              prefix={"/gallery/videos/"}
            />
          </div>
        </div>
      </main>
    </Layout>
  )
}
